<template>
  <b-modal
    id="modal-add-edit-region"
    size="md"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    v-model="mostrarModal"
    dialog-class="modal-dialog-scrollable"
    v-if="mostrarModal"
    @close="handleModalClose"
  >
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"
          ><i class="fa fa-fw fa-map-marker-alt"></i>
          {{ regionId ? "Editar" : "Agregar" }} región
        </span>
      </div>
    </template>
    <b-form id="frm-add-edit-region">
      <div class="row pt-1">
        <div class="col-md-12">
          <label class="font-weight-bold">Nombre</label>
          <b-form-input
            id="input-1"
            v-model="region.nombre"
            required
            placeholder=""
          ></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Código (mapa)</label>
          <b-form-input
            id="input-2"
            v-model="region.codigoMapa"
            required
            placeholder=""
          ></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Orden</label>
          <b-form-input
            id="input-3"
            v-model="region.orden"
            required
            placeholder=""
          ></b-form-input>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click.prevent="handleModalClose()"
      >
        <i class="fa fa-ban"></i> Cancelar
      </button>
      <button
        type="button"
        class="btn btn-primary font-weight-bold"
        @click.prevent="doSave()"
      >
        <i class="fa fa-save"></i> Guardar
      </button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
export default {
  name: "add-edit-region",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    regionId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    region: {
      regionId: null,
      nombre: "",
      codigoMapa: "",
      orden: null,
    },
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    doSave: async function() {
      debugger;
      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      if (!this.isFormValid("frm-add-edit-region")) {
        return this.sweetAlert(
          "Hay campos con valores incorrectos. Revise los datos ingresados."
        );
      }

      var model = {
        ...this.region,
      };

      this.estaGuardando = true;
      await this.$http
        .post(this.apiRoute("Region", "AddEditRegion"), model)
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.sweetAlert(response.body.message, "success");
            this.mostrarModal = false;
          }
        }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
    },
    fetchRegion: function(regionId) {
      if (regionId) {
        this.$http
          .get(this.apiRoute("Region", "GetRegionById"), {
            params: {
              RegionId: regionId,
            },
          })
          .then(function(response) {
            this.region = response.body.data;
          });
      }
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.region.regionId = this.regionId;
    this.initParsley();
    this.fetchRegion(this.region.regionId);
  },
  destoyed: function() {},
};
</script>
